import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    max-width: 1050px;
    border: 1px solid #E9E9E9;
    border-radius: 5px;
    padding: 60px;
    background: #ffffff;
`;
const Title = styled.div`
    font-weight: 700;
    font-size: 14px;
    color: #000;
    margin-bottom: 20px;
`;
const Text = styled.div`
    color: #686868;
    letter-spacing: 0;
    line-height: 22px;
    margin-bottom: 20px;
`;
const Link = styled.a`
    color: #686868;
    font-weight: 600;

    &:hover {
      color: #FF3330;
      transition-duration: 0.2s;
    }
`;
const CguFr = () => (
  <Container>
    <Title>
      Preamble
    </Title>
    <Text>
      Paris-Saclay Start-Up is a community information portal for start-ups in the Paris-Saclay ecosystem.
      whose aim is to facilitate their development in the Paris-Saclay area and the interactions between them,
      as well as with all the actors of economic development, be they academics, industrialists, investors, etc.
      or institutional.
      <br /><br />
      Its creation was decided by the Etablissement Public d'Aménagement Paris-Saclay and the University of Paris-Saclay. 
      at the joint management committee meeting of the two institutions on 12 December 2016. Its creation was carried out
      with the support of Start in Saclay, the Paris-Saclay student entrepreneur association.
      <br /><br />
      Its animation is realized, since March 24, 2020, by <Link href="https://lafrenchtech-paris-saclay.fr/" target="_blank">La French Tech Paris-Saclay</Link> et&nbsp;
      <Link href="https://www.epaps.fr/" target="_blank">the Etablissement Public d'Aménagement Paris-Saclay</Link>.
    </Text>
    <Title>
      Portal registration conditions
    </Title>
    <Text>
      paris-saclay-startup.com is a registration, promotion and information distribution service.
      on start-ups from the Paris-Saclay ecosystem.
      <br /><br />
      The data is collected and processed by <Link href="https://www.epaps.fr/" target="_blank">the Etablissement Public d'Aménagement Paris-Saclay</Link>. 
      and <Link href="https://lafrenchtech-paris-saclay.fr/" target="_blank">La French Tech Paris-Saclay</Link>.
      <br /><br />
      The following information is available to the general public without prior registration:
      <br /><br />
      The elements accessible to the general public are the general information allowing the identification of
      start-ups in the Paris-Saclay ecosystem, namely:
      <br /><br />
      - Company information: company name; company logo; the creation year ; a description of the activities of
      the company; the URL of the company's website; any pages promoting the company in the
      social networks; the activity sectors; the tags filled in.
      <br /><br />
      - Economic and technological data: the establishments in which has been - or is still at the time of
      registration - incubated / fast-tracked / hosted the company if applicable; partnerships with laboratories
      if applicable.
      <br /><br />
      Consequently, when you register, you give your consent for the above mentioned data
      are visible to the general public, without their consultation being subject to a condition of registration on the basis of
      Paris-Saclay Start-Up data.
      <br /><br />
      Information visible only to registered users :
      <br /><br />
      The following information is only available upon prior registration in the database
      Paris-Saclay Start-Up :<br />
      - Company information: SIRET number<br />
      - Personal information concerning the creators of the company: Photo (possibly provided),
      personal email, phone number <br />
      - Economic and technological data: Turnover (if provided); Fundraising activities carried out
      (if indicated); Fundraising sought (if indicated); Company's workforce; Possible rewards and awards; and
      Price (if informed) ; Markets on which the company is positioned (its products and/or services) ;
      Fields of expertise of the company, Keywords chosen to facilitate the research on the portal (if informed);
      Patents held by the company (if indicated).
      <br /><br />
      Consequently, when you register, you give your consent for the data listed
      above (which you will have provided voluntarily by filling in the mandatory and possibly optional fields)
      become visible to previously registered users, (creators of start-ups in the ecosystem, etc.)
      Paris-Saclay and investors).
    </Text>
    <Title>Consulting</Title>
    <Text>
      Consultation or receipt of documents does not entail any transfer of intellectual property rights.
      in favor of the user.<br />
      The latter undertakes not to redistribute or reproduce the data provided.<br />
      The data transmitted is processed in accordance with current practice.
      (See page <Link href="%PUBLIC_URL%/../pdf/security-policy.pdf" target="_blank">" SECURITY-POLICY AND PRIVACY POLICY "</Link>)<br />
      The publisher provides the information and associated services as is and cannot grant a
      guarantee of any kind, in particular for the reliability, topicality and completeness of the stored data.
      The user searches, selects and interprets the data on his own responsibility.<br />
      All information is given as an indication.<br />
      Any errors in the files can be reported via the "Contacts" page of the site.
    </Text>
    <Title>Privacy</Title>
    <Text>
      <Link href="https://www.epaps.fr/" target="_blank">the Etablissement Public d'Aménagement Paris-Saclay</Link> and <Link href="https://lafrenchtech-paris-saclay.fr/" target="_blank">La French Tech Paris-Saclay</Link> reserve the right
      to contact any person registered on the site.
    </Text>
    <Title>Cookie Usage</Title>
    <Text>
      This website requires the use of cookies.<br />
      Non-personal information is recorded by this cookie system.
      (text files used to recognize a user and thus facilitate their use of the site).
    </Text>
    <Title>Terms and conditions of use</Title>
    <Text>
    The data contained on the paris-saclay-startup.com website are protected by the law n° 98-536 of July 1st.
      1998 on legal protection for databases.
      By accessing or using the site, you agree to comply with the provisions of the law, and
      in particular by prohibiting you from extracting, transferring, storing, reproducing all or part of it.
      qualitatively or quantitatively substantial of the content of the databases appearing on the site.
      <br />
      <strong>
        The automatic reproduction, rebroadcasting or extraction by any means of information appearing on
        paris-saclay-startup.com is prohibited. The use of robots, programs that allow direct data retrieval
        is strictly forbidden.
      </strong>
      <br /><br />
      <Link href="https://www.epaps.fr/" target="_blank">The Etablissement Public d'Aménagement Paris-Saclay</Link> and <Link href="https://lafrenchtech-paris-saclay.fr/" target="_blank">La French Tech Paris-Saclay</Link> reserve the
      right to take any action to stop such conduct.
      <br /><br />
      The paris-saclay-startup.com site has been declared to the CNIL in application of article 16 of the law of
      January 6, 1978 relating to data processing, files and freedoms (Receipt N°2106856V0).
      The data collected from you are subject to computerised processing.
      <br /><br />
    </Text>
    <Title>Personal data</Title>
    <Text>
      The EPA Paris Saclay as data controller undertakes to ensure that the collection and processing of your data complies with the Data Protection Act of January 6, 1978 and the new general data protection regulations known as GDPR (EU) 2016/679 of April 27, 2016, in force from May 6, 2018.

      The information collected, in particular during your registration, is subject to computer processing.

      The personal data thus collected will not be kept for more than 3 years.

      In accordance with the regulations, you have the right to access, rectify and delete information concerning you, you can access this information by sending a message to our DPO (Personal Data Protection Officer) at following email address: dpo@oin-paris-saclay.fr
    </Text>
  </Container>
);

export default CguFr;
