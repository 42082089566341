export const eventsHomeInfos = [
  {
    id: 'events',
    img: 'events',
    link: 'https://lafrenchtech-paris-saclay.fr/event/'
  },
  {
    id: 'candidature',
    img: 'candidature',
    link: 'https://lafrenchtech-paris-saclay.fr/appels-a-projets/'
  },
  {
    id: 'frenchTech',
    img: 'frenchTech',
    link: 'https://rdv.lafrenchtech-paris-saclay.fr/'
  }
];