import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
    margin: 100px auto 0;
    background-color: #2e1738;
    position: relative;
    width: 100%;
    overflow: hidden;
`;
const Row = styled.div`
    max-width: 1050px;
    margin: 0 auto;
    position: relative;
    padding-top: 170px;
    padding-bottom: 220px;
    overflow: hidden;

    @media (max-width: 855px) {
        padding-top: 110px;
    }
    @media (max-width: 600px) {
        padding-bottom: 180px;
    }
`;
const BlocText = styled.div`
    margin-left: 70px;
    position: relative;
    z-index: 2;

    @media (max-width: 600px) {
        margin-left: 0;
        padding: 0 40px;
    }
`;
const RocketImg = styled.img`
    max-width: 100%;
    display: block;
    position: absolute;
    bottom: 30px;
    right: 0;
`;
const Elem1 = styled.img`
    position: absolute;
    top: 0;
    left: 70px;

    @media (max-width: 855px) {
        width: 245px;
    }
`;
const BlocElem2 = styled.div`
    max-width: 1050px;
    margin: 0 auto;
    position: relative;
`;
const Elem2 = styled.img`
    position: absolute;
    bottom: 0px;
    left: -305px;

    @media (max-width: 855px) {
        width: 395px;
    }
`;
const Elem3 = styled.img`
    position: absolute;
    top: 50px;
    right: -50px;

    @media (max-width: 1290px) {
        right: -200px;
    }

    @media (max-width: 855px) {
        display: none;
    }
`;
const Title = styled.h1`
    font-size: 44px;
    color: #ffffff;
    letter-spacing: 0;

    @media (max-width: 855px) {
        font-size: 35px;
    }
`;
const TitleColor = styled.h1`
    font-size: 44px;
    color: #FF3330;
    letter-spacing: 0;
    margin-bottom: 10px;

    @media (max-width: 855px) {
        font-size: 35px;
    }

`;
const SpanIntro = styled.span`
    display: block;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 40px;

    @media (max-width: 855px) {
        font-size: 14px;
    }
`;
const SignIn = styled.a`;
    background: #FF3330;
    border-radius: 5px;
    color: #FFFFFF;
    font-weight: 500;
    letter-spacing: 0;
    text-align: center;
    line-height: 33px;
    padding: 12px 23px;
    text-decoration: none;
    
`;
const IntroHome = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Row>
        <BlocText>
          <Title>Paris-Saclay</Title>
          <TitleColor>Startup</TitleColor>
          <SpanIntro>
            {t('homepage.intro.subtitle')}
          </SpanIntro>
          <SignIn href="https://premium.paris-saclay-startup.com/" target="_blank">
            {t('homepage.intro.button')}
          </SignIn>
        </BlocText>
        <RocketImg src={`${process.env.PUBLIC_URL}/img/rocket.png`} alt="Paris-Saclay" />
        <Elem1 src={`${process.env.PUBLIC_URL}/img/element-1.svg`} alt="Paris-Saclay" />
      </Row>
      <BlocElem2>
        <Elem2 src={`${process.env.PUBLIC_URL}/img/element-2.svg`} alt="Paris-Saclay" />
      </BlocElem2>
      <Elem3 src={`${process.env.PUBLIC_URL}/img/element-3.svg`} alt="Paris-Saclay" />
    </Container>
  );
};

export default IntroHome;
