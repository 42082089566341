import React from 'react';
import { useSpring, animated } from 'react-spring';
import PropTypes from 'prop-types';

const CounterAnimation = (props) => {
  const { count } = props;
  const spring = useSpring({ config: { duration: 1000 }, count, from: { count: 0 } });

  return (
    <animated.span>
      {spring.count.interpolate((val) => Math.floor(val))}
    </animated.span>
  );
};

CounterAnimation.propTypes = {
  count: PropTypes.number.isRequired,
};

export default CounterAnimation;
