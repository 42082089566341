import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import useSWR from 'swr';
import keyBy from 'lodash/keyBy';

import WithLayout from 'layout/WithLayout';
import { getAllEvents, fetcher } from 'pages/utils';
import EventsComponent from 'components/events/Events';
import Intro from 'components/Intro';
import ModalComponent from 'components/Modal';

const Container = styled.div`
    max-width: 1050px;
    margin: 0 auto;    
    position: relative;
    margin-top: -50px;
    margin-bottom: 30px;

    @media (max-width: 1100px) {
        padding-left:15px;
        padding-right: 15px;
    }
`;

const Events = (props) => {
  const id = get(props, 'match.params.id');
  const isModal = get(props, 'isModal');
  const isExternal = get(props, 'isExternal');
  const { data, error } = useSWR(`${process.env.REACT_APP_API_URL}/services/api.php?task=getConfig`, fetcher);
  const events = getAllEvents(data);
  const eventMap = keyBy(events, 'id');
  const event = id && Object.keys(eventMap).length ? eventMap[id] : [];

  if (error) return <div>failed to load</div>;
  const loading = !data;

  return (
    <>
      <Intro
        isEvents
      />
      <Container>
        <EventsComponent
          loading={loading}
          events={events}
          filter
        />
      </Container>
      {
        isModal
          ? (
            <ModalComponent
              event={event}
              loading={loading}
              isExternal={isExternal}
            />
          )
          : null
      }
    </>
  );
};

export default WithLayout(withRouter(Events));
