import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { withRouter } from "react-router";

const ScrollToTop = (props) => {
  const { pathname, isModal } = useLocation();
  const { history: { action } } = props;

  useEffect(() => {
    if (!isModal && action !== 'POP') {
      window.scrollTo(0, 0);
    }
  }, [isModal, pathname]);

  return null;
}

export default withRouter(ScrollToTop);