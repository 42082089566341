import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const Container = styled.div`
    margin: 100px auto 0;
    background-color: #2e1738;
    position: relative;
    width: 100%;
    padding: 140px 0;
    overflow: hidden;

    @media (max-width: 855px) {
        padding-top: 80px;
    }
`;
const Row = styled.div`
    max-width: 1050px;
    margin: 0 auto;
    text-align: center;
    position: relative;

    @media (max-width: 600px) {
      padding: 0 20px;
    }
`;
const Elem1 = styled.img`
    position: absolute;
    top: -140px;
    left: -20px;

    @media (max-width: 855px) {
        width: 220px;
        top: -80px;
    }
`;
const BlocElem2 = styled.div`
  max-width: 1050px;
  margin: 0 auto;
  position: relative;
`;
const Elem2 = styled.img`
    position: absolute;
    left: -305px;
    top: 40px;

    @media (max-width: 855px) {
      width: 395px;
      top: 50px;
    }

    @media (max-width: 610px) {
      width: 395px;
    }

    @media (max-width: 350px) {
      top: 86px;
    }
`;
const Elem3 = styled.img`
    position: absolute;
    top: 120px;
    right: 0px;

    @media (max-width: 1620px) {
        width: 245px;
        top: 145px;
        right: -172px;
    }

    @media (max-width: 1200px) {
      display: none;
  }
`;
const Title = styled.h1`
    font-size: 44px;
    color: #FFFFFF;
    margin-bottom: 20px;

    @media (max-width: 855px) {
        font-size: 35px;
    }

    @media (max-width: 600px) {
      font-size: 30px;
    }
`;
const H1Color = styled.span`
    font-size: 44px;
    color: #FF3330;
    margin-bottom: 20px;

    @media (max-width: 855px) {
        font-size: 35px;
    }

    @media (max-width: 600px) {
        font-size: 30px;
    }
`;
const SubTitle = styled.div`
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 20px;

    @media (max-width: 855px) {
      font-size: 14px;
  }

  @media (max-width: 600px) {
      font-size: 12px;
  }
`;

const Intro = (props) => {
  const { isEvents, isCgu } = props;
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <BlocElem2>
          <Elem2 src={`${process.env.PUBLIC_URL}/img/element-2.svg`} alt="Paris-Saclay" />
        </BlocElem2>
        <Elem3 src={`${process.env.PUBLIC_URL}/img/element-3.svg`} alt="Paris-Saclay" />
        { isEvents
          ? (
            <Row>
              <Elem1 src={`${process.env.PUBLIC_URL}/img/element-1.svg`} alt="Paris-Saclay" />  
              <Title>
                {t('eventspage.intro.title')}
                <H1Color> Paris-Saclay</H1Color>
              </Title>
              <SubTitle>{t('eventspage.intro.subtitle')}</SubTitle>
            </Row>
          )
          : null}
        { isCgu
          ? (
            <Row>
              <Elem1 src={`${process.env.PUBLIC_URL}/img/element-1.svg`} alt="Paris-Saclay" />
              <Title>{t('cgupage.intro.title')}</Title>
              <SubTitle>{t('cgupage.intro.subtitle')}</SubTitle>
            </Row>
          )
          : null}
      </Container>
    </>
  );
};

Intro.propTypes = {
  isEvents: PropTypes.bool.isRequired,
  isCgu: PropTypes.bool,
};

Intro.defaultProps = {
  isCgu: false,
};
export default Intro;
