import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  display: flex;
`;
const Image = styled.img`
  width: 260px;
  height: auto;
  margin: auto 0;
`;

const Brand = () => (
  <StyledLink to="/">
    <Image src={`${process.env.PUBLIC_URL}/img/logo.png`} alt="Paris-Saclay" />
  </StyledLink>
);

export default Brand;
