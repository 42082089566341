import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { ContainerQuery } from 'react-container-query';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DotLoader from 'react-spinners/DotLoader';
import moment  from 'moment';
import { useTranslation } from 'react-i18next';

import Counter, { Col } from 'components/counter/Counter';

const query = {
  small: {
    maxWidth: 600,
  },
  medium: {
    minWidth: 601,
    maxWidth: 855,
  },
  large: {
    minWidth: 856,
  },
};
const Maj = styled.div`
  max-width: 1050px;
  position absolute;
  z-index: 1;
  right: 0;
  bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  
  @media (max-width: 650px) {
    bottom: 5px;
  }
`;
const MajText = styled.div`
  color: rgba(0,0,0,0.3);
  font-size: ${({ large }) => (large ? '11px' : '9px')};

  @media (max-width: 630px) {
    font-size: 7px;
  }
`;
const StyledDotLoader = styled.span`
  margin-right: 10px;
`;
const Row = styled.div`
  max-width: 1050px;
  background: #ffffff;
  position relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  margin: 0 -15px;
  margin: 50px 0;
  border:  1px solid #E9E9E9;
  border-radius: 5px;
  margin: 0 auto;
  padding: ${({ small }) => (small ? '20px 0' : '60px 0')};

  ${Col} {
      min-width: 33.33%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right:  1px solid #E9E9E9;
      padding: ${({ large }) => (large ? null : '0 15px')};
      flex-direction: ${({ small }) => (small ? 'column' : 'row')};

      &:last-child {
          border-right: none;
      }
  }
`;

const Counters = (props) => {
  const { counters, loading, maj } = props;
  const { t } = useTranslation();
  const lastUpdate = maj ? moment(maj*1000).fromNow() : '';
  return (
    <ContainerQuery query={query}>
      {
        (params) => {
          const { large, medium, small } = params;
          return (
            <>
            <VisibilitySensor partialVisibility={true}>
              {({ isVisible }) => (
              <>
              <Row 
                large={large}
                small={small}
              >
                {
                  Object.values(counters).map((item) => (
                      <Counter
                        key={item.title}
                        counter={item}
                        large={large}
                        medium={medium}
                        small={small}
                        isVisible={isVisible}
                      />
                    ))
                }
                
                <Maj>
                  {loading ?
                  <StyledDotLoader>
                    <DotLoader 
                      size={13}
                      color= {"rgba(0,0,0,0.3)"}
                    />
                  </StyledDotLoader>
                    :
                    null
                  }
                  <MajText large={large}>
                    {t('homepage.counter.update')}{lastUpdate}
                  </MajText>
                </Maj>
              </Row>
              </>
            )}
          </VisibilitySensor>
          </>
          );
        }
      }
    </ContainerQuery>
  );
};

Counters.propTypes = {
  counters: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      count: PropTypes.number,
      img: PropTypes.string,
      color: PropTypes.string,
    }).isRequired,
  ).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Counters;
