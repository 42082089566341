import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const WithLayout = (Content) => {
  return (props) => (
    <>
      <Header />
      <Content {...props} />
      <Footer />
    </>
  );
};

export default WithLayout;
