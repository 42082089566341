import React from "react";
import { ContainerQuery } from "react-container-query";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const query = {
  small: {
    maxWidth: 600,
  },
  large: {
    minWidth: 856,
  },
};

const Container = styled.div`
  background: #f7f7f7;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 30px;
`;
const ColElement = styled.div`
  background: #f7f7f7;
  position: relative;
  height: 75px;
`;
const Element = styled.img`
  position: absolute;
  width: 132px;
  right: 160px;
  right: ${({ large }) => (large ? "160px" : "80px")};
`;
const RowMap = styled.div`
  display: flex;
  flex-direction: ${({ large, imgRight }) =>
    large ? (imgRight ? "row" : "row-reverse") : "column"};
  ${({ large }) => large && "padding: 0 20px"};
`;
const ColText = styled.div`
  flex: 1;
  min-width: ${({ large }) => (large ? "calc(50% - 25px)" : "100%")};
  padding: ${({ large, isMap }) =>
    large ? (isMap ? "15px 50px" : "60px 50px") : "40px 50px"};
`;
const ColImg = styled.div`
  flex: 1;
  min-width: ${({ large }) => (large ? "calc(50% - 25px)" : "100%")};
  display: ${({ large }) => (large ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;
const Img = styled.img`
  width: 100%;
  display: block;
`;
const Title = styled.h2`
  font-size: 20px;
  color: #000000;
  margin-bottom: 20px;
`;
const Text = styled.p`
  color: #686868;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 40px;
`;
const Button = styled.a`
  background: #ff3330;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  letter-spacing: 0;
  text-align: center;
  line-height: 33px;
  padding: 12px 23px;
  text-decoration: none;
  display: ${({ small }) => (small ? "block" : null)};
  padding: ${({ small }) => (small ? "3px 0" : "12px 23px")};
`;

const ImgLink = styled.a`
  cursor: pointer;
`;

const BlocHome = ({ id, img, url, imgRight }) => {
  const isMap = id === "map";
  const { t } = useTranslation();

  return (
    <ContainerQuery query={query}>
      {(params) => {
        const { large, small } = params;
        return (
          <Container large={large}>
            {isMap && (
              <ColElement>
                <Element
                  src={`${process.env.PUBLIC_URL}/img/map-element.png`}
                  large={large}
                />
              </ColElement>
            )}
            <RowMap large={large} imgRight={imgRight}>
              <ColText isMap={isMap} large={large}>
                <Title
                  dangerouslySetInnerHTML={{
                    __html: t(`homepage.blocHomeInfos.${id}.title`),
                  }}
                />
                <Text
                  dangerouslySetInnerHTML={{
                    __html: t(`homepage.blocHomeInfos.${id}.text`),
                  }}
                />
                <Button href={url} target="_blank" small={small}>
                  {t(`homepage.blocHomeInfos.${id}.link`)}
                </Button>
              </ColText>
              <ColImg large={large}>
                <ImgLink href={url} target="_blank">
                  <Img src={`${process.env.PUBLIC_URL}/img/${img}.png`} />
                </ImgLink>
              </ColImg>
            </RowMap>
          </Container>
        );
      }}
    </ContainerQuery>
  );
};

export default BlocHome;
