import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
    position: relative;
    cursor: pointer;
    display: block;
    margin:0 15px;

    & span {
        background: #000000;
        padding-top: 4px;
        display: block;
        position: relative;
        width: 26px;
        height: 4px;
        height: 5;
        margin-bottom: 5px;
        -webkit-transition: all ease-in-out 0.2s;
        transition: all ease-in-out 0.2s;
    }

    .open span:nth-child(2) {
        opacity: 0;
        }

    .open span:nth-child(3) {
        transform: rotate(-45deg);
        top: -8px;
    }

    .open span:nth-child(1) {
        transform: rotate(45deg);
        top: 10px;
    }
`;

const Burgermenu = (props) => {
  const { handleNavbar, navbarState } = props;
  return (
    <Wrapper onClick={handleNavbar}>
      <div className={navbarState ? 'open' : ''}>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
      </div>
    </Wrapper>
  );
};

Burgermenu.propTypes = {
  navbarState: PropTypes.bool.isRequired,
  handleNavbar: PropTypes.func.isRequired,
};

export default Burgermenu;
