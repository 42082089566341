import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import jsonfr from 'langs/fr.json';
import jsonen from 'langs/en.json';

const resources = {
  fr: {
    translation: jsonfr,
  },
  en: {
    translation: jsonen,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'fr',
    fallbackLng: 'fr',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
