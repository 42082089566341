export const MAP_URL = "https://paris-saclay-startup.com/map";

export const blocHomeInfos = [
  {
    id: 'map',
    img: 'map',
    url: MAP_URL,
    imgRight: true
  },
  {
    id: 'startupper',
    img: 'startupper',
    url: 'https://lafrenchtech-paris-saclay.fr/services/dtfn/',
    imgRight: false
  },
  {
    id: 'cartography',
    img: 'cartography',
    url: `${process.env.PUBLIC_URL}/pdf/cartography.pdf`,
    imgRight: true
  }
]