import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const Col = styled.a`
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  margin: 0px 15px;
`;

const Container = styled.div`
  padding: 15px 20px 0 20px;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`;
const Title = styled.h3`
  font-size: 16px;
  color: #000000;
  margin-bottom: 15px;
`;
const BlocInfo = styled.div`
  font-size: 12px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: capitalize;
`;
const Hr = styled.hr`
  height: 1px;
  border-width: 0;
  background: #d2d2d2;
`;
const Desc = styled.p`
  color: #686868;
  letter-spacing: 0;
  line-height: 22px;
  margin: 15px 0;
  flex: 1 0 auto;
`;
const SpanButton = styled.div`
  display: block;
  text-align: center;
  background: #ff3330;
  border-radius: 5px;
  color: #ffffff;
  padding: 10px 10px;
  text-decoration: none;
  margin: 0 0 20px;
`;
const BlocImg = styled.div`
  overflow: hidden;
  height: 120px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;
const Img = styled.img`
  width: 100%;
  display: block;
  object-position: 50% 50%;
  object-fit: cover;
  height: 120px;
  transition: all 8s;
`;

const New = (props) => {
  const {
    new: { title, link, source, publicationDate, description, illustration },
  } = props;
  const { t } = useTranslation();

  return (
    <Col href={link} target="_blank">
      <BlocImg>
        <Img src={illustration} alt={title} />
      </BlocImg>
      <Container>
        <Title>{title}</Title>
        <BlocInfo>
          {source} - {publicationDate}
        </BlocInfo>
        <Hr />
        <Desc>{description}</Desc>
      </Container>
    </Col>
  );
};

New.propTypes = {
  new: PropTypes.shape({
    description: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    publicationDate: PropTypes.string,
    source: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default New;
