import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Home from "pages/Home";
import { MAP_URL } from "components/utils";

const StyledLink = styled(NavLink)`
  display: block;
`;

const Events = styled.a`
  margin: 10px;
`;

const Map = styled.a`
  margin: 10px;
`;

const LinkMenu = () => {
  const { t } = useTranslation();
  const activeColor = { color: "#FF3330" };
  return (
    <>
      <li>
        <StyledLink activeStyle={activeColor} to="/" render={() => Home} exact>
          {t("navbar.home")}
        </StyledLink>
      </li>
      <li>
        <Events
          href="https://lafrenchtech-paris-saclay.fr/event/"
          target="_blank"
        >
          {t("navbar.events")}
        </Events>
      </li>
      <li>
        <StyledLink activeStyle={activeColor} to="/cgu">
          {t("navbar.conditions")}
        </StyledLink>
      </li>
      <li>
        <Map href={MAP_URL} target="_blank">
          {t("navbar.map")}
        </Map>
      </li>
    </>
  );
};

export default LinkMenu;
