import React from 'react';
import videoSrc from 'videos/paris-saclay-innovation-playground.mp4';
import styled from 'styled-components';

const Video = styled.video`
border-radius: 5px;
`;

const VideoScreen = () => (
  <Video width="100%" controls autoPlay muted>
    <source src={videoSrc} type="video/mp4" />
  </Video>
);

export default VideoScreen;
