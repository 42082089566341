import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import { useSpring, animated, config } from 'react-spring';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import Brand from 'components/navbar/Brand';
import BurgerMenu from 'components/navbar/BurgerMenu';
import CollapseMenu from 'components/navbar/CollapseMenu';
import LinkMenu from 'components/navbar/LinkMenu';

const NavBar = styled(animated.nav)`
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: #ffffff;
    z-index: 3;
`;
const FlexContainer = styled.div`
    max-width: 1050px;
    display: flex;
    margin: auto;
    justify-content: space-between;
    height: 100px;
    padding: 10px 0;
    padding-left: 10px;
    border-bottom: 1px solid #E9E9E9;
    letter-spacing: 0;
    text-align: center;
    line-height: 60px;
`;
const NavLinks = styled(animated.ul)`
    justify-self: end;
    list-style-type: none;
    margin: auto 0;

    & a {
        color: #000000;
        font-weight: 600;
        border-bottom: 1px solid transparent;
        margin-right: 30px;
        transition: all 300ms linear 0s;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            color: #FF3330;
            transition-duration: 0.2s;
        }

        @media (max-width: 855px) {
            display: none;
        }
    }
`;
const BurgerWrapper = styled.div`
    margin: auto 0;

    @media (min-width: 855px) {
        display: none;
    }
`;
const Container = styled.div`
    margin: auto;
    display: flex;
    justify-content: space-between;
`;
const Menu = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 10px;

    &:after {
        content: "";
        position: absolute;
        top: 20%;
        right: 0;
        width: 1px;
        height: 60%;
        background: rgba(0,0,0, 0.3);
    }

    @media (max-width: 855px) {
        display: none;
    }
`;
const Lang = styled.div`
    display: flex;
    align-items: center;
    margin: 0 10px;
`;
const SpanLangFr = styled.span`
    font-weight: 600;
    margin: 0 10px;
    transition: all 300ms linear 0s;
    text-decoration: none;
    cursor: pointer;
    color: ${({ lang }) => (lang === 'fr' ? '#FF3330' : 'rgba(0,0,0, 0.3)')};

    &:hover {
        color: #FF3330;
        transition-duration: 0.2s;
    }
`;
const SpanLangEn = styled.span`
    font-weight: 600;
    margin: 0 10px;
    transition: all 300ms linear 0s;
    text-decoration: none;
    cursor: pointer;
    color: ${({ lang }) => (lang === 'en' ? '#FF3330' : 'rgba(0,0,0, 0.3)')};

    &:hover {
        color: #FF3330;
        transition-duration: 0.2s;
    }
`;

const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const { i18n } = useTranslation();
  const lang = i18n.languages[0];

  const handleNavbar = () => {
    setNavbarOpen(!navbarOpen);
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    moment.locale(lang);
  }
  const barAnimation = useSpring({
    from: { transform: 'translate3d(0, -10rem, 0)' },
    transform: 'translate3d(0, 0, 0)',
  });

  const linkAnimation = useSpring({
    from: { transform: 'translate3d(0, 30px, 0)', opacity: 0 },
    to: { transform: 'translate3d(0, 0, 0)', opacity: 1 },
    delay: 800,
    config: config.wobbly,
  });

  return (
    <>
      <NavBar style={barAnimation}>
        <FlexContainer>
          <Brand />
          <NavLinks style={linkAnimation}>
            <Route>
              <Container>
                <Menu>
                  <LinkMenu />
                </Menu>
                <Lang>
                  <SpanLangFr lang={lang} onClick={() => changeLanguage('fr')}>FR</SpanLangFr>
                  <SpanLangEn lang={lang} onClick={() => changeLanguage('en')}>EN</SpanLangEn>
                </Lang>
                <BurgerWrapper>
                  <BurgerMenu
                    navbarState={navbarOpen}
                    handleNavbar={handleNavbar}
                  />
                </BurgerWrapper>
              </Container>
            </Route>
          </NavLinks>
        </FlexContainer>
      </NavBar>
      <CollapseMenu
        navbarState={navbarOpen}
        handleNavbar={handleNavbar}
      />
    </>
  );
};

export default Navbar;
