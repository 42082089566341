import React, { useState } from 'react';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import 'react-dates/lib/css/_datepicker.css';
import {
  START_DATE,
  END_DATE,
  VERTICAL_ORIENTATION,
  HORIZONTAL_ORIENTATION
} from 'react-dates/constants';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/fr';

import 'datepicker.css';

const falseFunc = () => false;

const FilterDate = (props) => {
  const { onChange, small } = props;
  const { t, i18n } = useTranslation();
  const lang = i18n.languages[0];
  moment.locale(lang);

  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocused] = useState(null);
  
  const handleChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    onChange({ startDate, endDate });
  };

  const formattedStartDate = startDate ? moment(startDate) : null;
  const formattedEndDate = endDate ? moment(endDate) : null;

  return (
    <DateRangePicker
      onDatesChange={(value) => handleChange(value)}
      startDate={formattedStartDate}
      focusedInput={focusedInput}
      onFocusChange={(focused) => setFocused(focused)}
      endDate={formattedEndDate}
      startDateId={START_DATE}
      startDatePlaceholderText={t('eventspage.filter.startDate')}
      endDateId={END_DATE}
      endDatePlaceholderText={t('eventspage.filter.endDate')}
      showDefaultInputIcon
      block
      small
      hideKeyboardShortcutsPanel
      isOutsideRange={falseFunc}
      renderMonthElement={({ month }) => (<strong>{moment(month).locale(lang).format('MMMM YYYY')}</strong>)}
      showClearDates
      orientation={small ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION}
    />
  );
};

FilterDate.propTypes = {
  small: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

FilterDate.defaultProps = {
  small: true,
};

export default FilterDate;
