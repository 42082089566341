import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';

import Home from 'pages/Home';
import Notfound from 'pages/Notfound';
import Events from 'pages/Events';
import Cgu from 'pages/Cgu';

const App = (props) => {
  const { location } = props;
  const modalLink = location
    && location.isModal;

  return (
    <>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/events">
          <Events />
        </Route>
        <Route exact path="/cgu">
          <Cgu />
        </Route>
        <Route exact path="/event/:id">
          <Events isModal isExternal={!modalLink} />
        </Route>
        <Route>
          <Notfound />
        </Route>
      </Switch>
    </>
  );
};

App.propTypes = {
  location: PropTypes.shape({
    isModal: PropTypes.bool
  }).isRequired
};

export default withRouter(App);
