import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
  Col,
  BlocImg,
  Img,
  BlocInfo,
  Title,
  Hr,
  Desc,
  SpanButton,
} from "./Event";
import { useTranslation } from "react-i18next";

const Container = styled.a`
  position: relative;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  cursor: pointer;

  &:hover {
    & img {
      transform: scale(1.2);
      transition: all 8s;
    }
    & h3 {
      color: ${({ oldevent }) => (oldevent ? "#000000;" : "#FF3330;")};
      transition-duration: 0.2s;
    }
  }
`;

const Bloc = styled(BlocImg)`
  height: 160px;
  padding: 20px 0;

  ${Img} {
    object-fit: contain;
  }
`;

const Infos = styled(BlocInfo)`
  text-align: center;
`;

const BlocTitle = styled(Title)`
  text-transform: uppercase;
`;

const EventsHome = (infos) => {
  const { id, title, img, link } = infos;
  const { t } = useTranslation();

  const src = `${process.env.PUBLIC_URL}/img/${img}.png`;
  return (
    <Col>
      <Container href={link} target="_blank">
        <Bloc>
          <Img src={src} alt={id} />
        </Bloc>
        <Hr />
        <Infos>
          <BlocTitle>{t(`homepage.eventsHome.${id}.title`)}</BlocTitle>
          {/* <Inno>{innoTitle}</Inno> */}
          <Hr />
          <Desc>{t(`homepage.eventsHome.${id}.description`)}</Desc>
        </Infos>
        <SpanButton>{t("events.modalLink")}</SpanButton>
      </Container>
    </Col>
  );
};

EventsHome.propTypes = {};

export default EventsHome;
