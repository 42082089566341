import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styled from "styled-components";
import PropTypes from "prop-types";
import DotLoader from "react-spinners/DotLoader";

import New from "components/news/New";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 855 },
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 855, min: 600 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const Container = styled.div`
  margin: 0 -15px;
  position: relative;
`;
const LeftButton = styled.div`
  display: ${({ hideLeftButton }) => (hideLeftButton ? "none" : null)};
  left: -10px;
  bottom: 50%;
  background: #008ac3;
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;

  &:hover {
    background: rgb(0, 138, 195, 0.5);
    transition-duration: 0.2s;
  }

  @media (max-width: 1120px) {
    left: 5px;
    background: rgb(0, 138, 195, 0.5);

    &:hover {
      background: #008ac3;
      transition-duration: 0.2s;
    }
  }
`;

const RightButton = styled.div`
  display: ${({ hideRightButton }) => (hideRightButton ? "none" : null)};
  right: -10px;
  bottom: 50%;
  background: #008ac3;
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;

  &:hover {
    background: rgb(0, 138, 195, 0.5);
    transition-duration: 0.2s;
  }

  @media (max-width: 1120px) {
    right: 5px;
    background: rgb(0, 138, 195, 0.5);

    &:hover {
      background: #008ac3;
      transition-duration: 0.2s;
    }
  }
`;
const Img = styled.img`
  position: absolute;
  bottom: 17px;
  right: 45%;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const CustomArrow = ({
  onClick,
  goToSlide,
  previous,
  loading,
  lastImageIndex,
  next,
  length,
  ...rest
}) => {
  const {
    carouselState: { currentSlide, slidesToShow },
  } = rest;

  return (
    <>
      <LeftButton
        hideLeftButton={currentSlide === 0 ? true : false}
        onClick={() => previous()}
        loading={loading}
      >
        <Img
          src={`${process.env.PUBLIC_URL}/img/picto-left-arrow.svg`}
          alt="Paris-Saclay"
        />
      </LeftButton>
      <RightButton
        hideRightButton={currentSlide === length - slidesToShow ? true : false}
        onClick={
          (() => goToSlide(currentSlide + 3),
          currentSlide === lastImageIndex ? goToSlide(0) : next)
        }
        loading={loading}
      >
        <Img
          src={`${process.env.PUBLIC_URL}/img/picto-right-arrow.svg`}
          alt="Paris-Saclay"
        />
      </RightButton>
    </>
  );
};

CustomArrow.propTypes = {
  onClick: PropTypes.func,
  goToSlide: PropTypes.func,
  previous: PropTypes.func,
  loading: PropTypes.bool,
};

CustomArrow.defaultProps = {
  onClick: () => {},
  goToSlide: () => {},
  previous: () => {},
  loading: true,
};

const News = (props) => {
  const { news, loading } = props;
  const lastImageIndex = Object.values(news).length - 1;
  const length = Object.values(news).length;

  return loading ? (
    <LoadingContainer>
      <DotLoader />
    </LoadingContainer>
  ) : (
    <Container>
      <Carousel
        responsive={responsive}
        autoPlaySpeed={1000}
        arrows={false}
        renderButtonGroupOutside
        customButtonGroup={
          <CustomArrow
            lastImageIndex={lastImageIndex}
            loading={loading}
            length={length}
          />
        }
      >
        {Object.values(news).map((item) => (
          <New key={item.id} new={item} />
        ))}
      </Carousel>
    </Container>
  );
};

News.propTypes = {
  news: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.number,
      illustration: PropTypes.string,
      link: PropTypes.string,
      publicationDate: PropTypes.string,
      source: PropTypes.string,
      status: PropTypes.number,
      title: PropTypes.string,
      type: PropTypes.string,
      yoomapid: PropTypes.string,
    }).isRequired
  ).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default News;
