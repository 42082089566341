import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CounterAnimation from 'components/counter/CounterAnimation';

export const Col = styled.div`
    flex: 1;
`;
const BlocImg = styled.div`
    margin-right: ${({ small }) => (small ? null : '30px')};
`;
const Logo = styled.img`
  width: ${({ small }) => (small ? '30px' : null)};
`;
const Desc = styled.div`
    color: ${({ color }) => color};
    text-align: ${({ small }) => (small ? 'center' : null)};
`;
const Title = styled.div`
    font-weight: 700;
    font-size: ${({ large }) => (large ? '44px' : null)};
    font-size: ${({ medium }) => (medium ? '34px' : null)};
    font-size: ${({ small }) => (small ? '24px' : null)};
    text-align: ${({ small }) => (small ? 'center' : null)};
`;
const Text = styled.div`
    font-size: ${({ large }) => (large ? '14px' : '12px')};
    font-size: ${({ medium }) => (medium ? '12px' : null)};
    font-size: ${({ small }) => (small ? '11px' : null)};
    font-weight: 500;
`;

const Counter = (props) => {
  const {
    counter: {
      count, img, color, title,
    },
    large, medium, small, isVisible,
  } = props;
  const { t } = useTranslation();
  let text = '';
  switch (title) {
    case 'startups':
      text = t('homepage.counter.startups');
      break;
      case 'investor':
      text = t('homepage.counter.investor');
      break;
      case 'ecosysteme':
      text = t('homepage.counter.inno');
      break;
    default: 
    console.log('null');
  }

  return (
      <Col>
        <BlocImg small={small}>
          <Logo 
            src={`${process.env.PUBLIC_URL}/img/${img}.svg`} alt="Paris-Saclay"
            small={small}
          />
        </BlocImg>
        <Desc 
          color={color}
          small={small}
        >
          <Title 
            large={large}
            medium={medium}
            small={small}
          >
          { isVisible ? 
            <CounterAnimation count={count} />
            : 0}
          </Title>
          <Text
            large={large}
            medium={medium}
            small={small}
            title={title}
          >
            {text}
          </Text>
        </Desc>
      </Col>
  );
};

Counter.propTypes = {
  counter: PropTypes.shape({
    count: PropTypes.number.isRequired,
    img: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  large: PropTypes.bool,
};

export default Counter;
