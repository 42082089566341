import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from 'App';
import 'i18n';

import ScrollToTop from 'components/ScrollToTop';
import { checkBrowser } from 'pages/utils';

if (!checkBrowser()) {
  window.location = (`${process.env.PUBLIC_URL}/browser.html`);
}

const routing = (
  <Router
    basename={`${process.env.REACT_APP_BASENAME}`}
  >
    <ScrollToTop />
    <App />
  </Router>
);

ReactDOM.render(routing, document.getElementById('root'));
