import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import PropTypes from 'prop-types';

import LinkMenu from 'components/navbar/LinkMenu';

const CollapseWrapper = styled(animated.div)`
    background: #ffffff;
    position: fixed;
    top: 100px;
    left: 0;
    right: 0;
    z-index:3;
    border-bottom: 1px solid #E9E9E9;

    @media (min-width: 855px) {
        display: none;
    }
`;

const NavLinks = styled.ul`
    list-style-type: none;
    padding: 1rem;
    text-align: center;
    
    & li {
        transition: all 300ms linear 0s;
    }

    & a {
        font-size: 16px;
        line-height: 2;
        color: #000000;
        font-weight: 600;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            color: #FF3330;
            transition-duration: 0.2s;
        }
    }
`;

const CollapseMenu = (props) => {
  const { navbarState } = props;
  const { open } = useSpring({ open: navbarState ? 0 : 1 });

  if (navbarState === true) {
    return (
      <CollapseWrapper style={{
        transform: open.interpolate({
          range: [0, 0.2, 0.3, 1],
          output: [0, -20, 0, -200],
        }).interpolate((openValue) => `translate3d(0, ${openValue}px, 0`),
      }}
      >
        <NavLinks>
          <LinkMenu />
        </NavLinks>
      </CollapseWrapper>
    );
  }
  return null;
};

CollapseMenu.propTypes = {
  navbarState: PropTypes.bool.isRequired,
};

export default CollapseMenu;
