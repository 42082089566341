import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Container = styled.div`
  max-width: 1050px;
  margin: 150px auto 10px;

  @media (max-width: 1100px) {
    padding-left: 15px;
    padding-right: 15px;
    margin: 40px auto 10px;
  }
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ColText = styled.div`
  flex: 1;
  color: #000000;
  font-weight: 600;

  @media (max-width: 600px) {
    font-size: 12px;
  }
`;
const Mailto = styled.a`
  text-decoration: none;
  color: #000000;
  font-weight: 600;
  margin-right: 5px;

  &:hover {
    color: #ff3330;
    transition-duration: 0.2s;
  }
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  color: #000000;
  font-weight: 600;
  margin-left: 5px;

  &:hover {
    color: #ff3330;
    transition-duration: 0.2s;
  }
`;
const Span = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #686868;
  margin-top: 3px;

  @media (max-width: 600px) {
    font-size: 10px;
  }
`;
const ColLogo = styled.div`
  flex: 1;
  text-align: end;
`;
const Img = styled.img`
  flex: 1;
  width: 260px;
`;

const Footer = () => {
  const { t } = useTranslation();
  const year = new Date().getFullYear();

  return (
    <Container>
      <Row>
        <ColText>
          <Mailto href="mailto:contact@paris-saclay-startup.com">
            {t("footer.contact")}
          </Mailto>
          |<StyledLink to="/cgu">{t("footer.conditions")}</StyledLink>
          <Span>
            {year} {t("footer.copyright")}
          </Span>
        </ColText>
        <ColLogo>
          <Img src={`${process.env.PUBLIC_URL}/img/logo.png`} />
        </ColLogo>
      </Row>
    </Container>
  );
};

export default Footer;
