import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import moment  from 'moment';
import { getImg } from 'pages/utils';

const ModalContainer = styled.div`
  width: 100%;
  position: relative;
  max-width: 450px;
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 95%;
  height: auto;
  margin-top: 15px;
  border-radius: 5px;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.75);
`;

const ModalWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 3;
  height: 100%;
  background-color: rgba(0,0,0,0.50);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
`;
const BlocImg = styled.div`
    overflow: hidden;
    height: 120px;
    flex: 1 0 auto;
`;
const Img = styled.img`
    width: 100%;
    display: block;
    object-position: 50% 50%;
    object-fit: cover;
    height: 120px;
    transition: all 10s;

    &:hover {
        transform: scale(1.2);
    }
    
`;
const BlocInfo = styled.div`
    padding: 20px;
    flex: 0 1 auto;
    overflow-y: auto;
`;
const Title = styled.h3`
    color: #111111;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0;
    margin-bottom: 5px;
`;
const BlocDate = styled.div`
    color: #111111;
    font-weight: 600;
    font-size: 11px;
    margin-bottom: 25px;
`;
const SpanArrow = styled.span`
    color: #FF3330;
`;
const Hr = styled.hr`
    height: 1px;
    border-width: 0;
    background: #F0F0F0;
    margin-bottom: 15px;
`;
const SubTitle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;
const ImgSubTitle = styled.img`
    width: 11px;
    height: 12px;
    margin-right: 10px;
`;
const CloseImg = styled.img`
  display: block;
  width: 7px;
  height: 8px;
`;
const CloseButton = styled.div`
  background: #2e1738;
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 11px;
  border-radius: 5px;
  cursor: pointer;
`;
const TextSubTitle = styled.div`
  color: #111111;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0;
`;
const Desc = styled.div`
    color: #686868;
    font-size: 11px;
    letter-spacing: 0;
    margin-left: 21px;
    margin-bottom: 10px;
`;
const BlocLinkButton = styled.div`
  background: #FFFFFF;
  padding: 20px;
  flex: 1 0 auto;
`;
const LinkButton = styled.a`
    display: block;
    text-align: center;
    background: #FF3330;
    border-radius: 5px;
    color: #FFFFFF;
    padding: 10px;
    text-decoration: none;
`;

const Modal = (props) => {
  const modalRef = useRef();
  const { t } = useTranslation();
  const {
    isExternal, event, history
  } = props;

  const {
    title,
    desc,
    date,
    date_fin: dateFin,
    address,
    partners,
    speakers,
    price,
    img,
    id,
    links
  } = event;

  const eventSrc = getImg(img);
  const parsedLinks = JSON.parse(links);
  const link = get(parsedLinks, '0.link');

  useEffect(() => {
    disableBodyScroll(modalRef.current);
    return () => {
      enableBodyScroll(modalRef.current);
    };
  }, []);

  const elements = {
    desc: {
      img: 'infos',
      text: 'modal.infos',
      description: desc,
    },
    address: {
      img: 'address',
      text: 'modal.address',
      description: address,
    },
    speakers: {
      img: 'speakers',
      text: 'modal.speakers',
      description: speakers,
    },
    partners: {
      img: 'partners',
      text: 'modal.partners',
      description: partners,
    },
    price: {
      img: 'price',
      text: 'modal.price',
      description: price,
    },
  };

  if (event === {}) { return <div>loading...</div>}

  return (
    <ModalWrapper
      ref={modalRef}
    >
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <BlocImg>
          <Img src={eventSrc} />
          <CloseButton onClick={() => isExternal ? history.push({
            pathname: '/events',
            isModal: false
            }) : history.goBack({
              isModal: false
            })
          }>
            <CloseImg
              src={`${process.env.PUBLIC_URL}/img/picto-close.svg`}
            />
          </CloseButton>
        </BlocImg>
        <BlocInfo>
          <Title>
            {title}
          </Title>
          <BlocDate>
            {moment(date).format(t('events.dateFormat'))}
            <SpanArrow> &rarr; </SpanArrow>
            {moment(dateFin).format(t('events.dateFormat'))}
          </BlocDate>
          <Hr />
          {
            Object.values(elements).map((element) => {
              const { description, text, img } = element;
              return (
                description
                  ? (
                    <>
                      <SubTitle>
                        <ImgSubTitle src={`${process.env.PUBLIC_URL}/img/picto-${img}.svg`} />
                        <TextSubTitle>{t(text)}</TextSubTitle>
                      </SubTitle>
                      <Desc>
                        {description}
                      </Desc>
                      <Hr />
                    </>
                  )

                  : null
              );
            })
          }
        </BlocInfo>
        {link ?
        <BlocLinkButton>
          <LinkButton
            href={link}
            target="_blank"
          >
            {t('events.modalLink')}
          </LinkButton>
        </BlocLinkButton>
        : null
        }
      </ModalContainer>
    </ModalWrapper>
  );
};

Modal.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string,
      date: PropTypes.instanceOf(Date),
      dateFin: PropTypes.instanceOf(Date),
      desc: PropTypes.string,
      id: PropTypes.number.isRequired,
      img: PropTypes.arrayOf(PropTypes.string),
      inno: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.number,
        title: PropTypes.string,
      })).isRequired,
      partners: PropTypes.string,
      price: PropTypes.string,
      speakers: PropTypes.string,
      title: PropTypes.string.isRequired,
      length: PropTypes.number,
    }).isRequired,
  ).isRequired,
  location: PropTypes.shape({
    src: PropTypes.string,
    address: PropTypes.string,
    date: PropTypes.instanceOf(Date),
    dateFin: PropTypes.instanceOf(Date),
    desc: PropTypes.string,
    partners: PropTypes.string,
    price: PropTypes.string,
    speakers: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }).isRequired,
};

export default withRouter(Modal);
