import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    max-width: 1050px;
    border: 1px solid #E9E9E9;
    border-radius: 5px;
    padding: 60px;
    background: #ffffff;
`;
const Title = styled.div`
    font-weight: 700;
    font-size: 14px;
    color: #000;
    margin-bottom: 20px;
`;
const Text = styled.div`
    color: #686868;
    letter-spacing: 0;
    line-height: 22px;
    margin-bottom: 20px;
`;
const Link = styled.a`
    color: #686868;
    font-weight: 600;

    &:hover {
      color: #FF3330;
      transition-duration: 0.2s;
    }
`;
const CguFr = () => (
  <Container>
    <Title>
      Préambule
    </Title>
    <Text>
      Paris-Saclay Start-Up est un portail communautaire d’information pour les start-ups de l’écosystème Paris-Saclay
      dont le but est de faciliter leur développement sur le territoire de Paris-Saclay et les interactions entre elles,
      ainsi qu’avec tous les acteurs du développement économique, qu’ils soient académiques, industriels, investisseurs
      ou institutionnels.
      <br /><br />
      Sa création a été décidée par l’Etablissement Public d’Aménagement Paris-Saclay et l’Université Paris-Saclay 
      lors du comité de direction paritaire des deux établissements, le 12 décembre 2016. Sa création a été réalisée
      avec le support de Start in Saclay, l’association des étudiants entrepreneurs de Paris-Saclay.
      <br /><br />
      Son animation est réalisée, depuis le 24 mars 2020, par <Link href="https://lafrenchtech-paris-saclay.fr/" target="_blank">La French Tech Paris-Saclay</Link> et&nbsp;
      <Link href="https://www.epaps.fr/" target="_blank">l’Etablissement Public d’Aménagement Paris-Saclay</Link>.
    </Text>
    <Title>
      Conditions d’inscription au portail
    </Title>
    <Text>
      paris-saclay-startup.com est un service d’inscription, de promotion et de diffusion d’informations
      sur les start-ups issues de l’écosystème de Paris-Saclay.
      <br /><br />
      Les données sont collectées et traitées par <Link href="https://www.epaps.fr/" target="_blank">l’Etablissement Public d’Aménagement Paris-Saclay</Link> 
      et <Link href="https://lafrenchtech-paris-saclay.fr/" target="_blank">La French Tech Paris-Saclay</Link>.
      <br /><br />
      Les informations suivantes sont accessibles au grand public sans inscription préalable:
      <br /><br />
      Les éléments accessibles au grand public sont les informations d’ordre général permettant l’identification
      des start-ups de l’écosystème Paris-Saclay, à savoir :
      <br /><br />
      - Informations sur l’entreprise : la raison sociale ; le logo de l’entreprise ; l’année de création ; une description des activités de
      l’entreprise ; l’URL du site Internet de l’entreprise ; les pages éventuelles de promotion de l’entreprise sur les
      réseaux sociaux ; les filières d’activité ; les tags renseignés.
      <br /><br />
      - Données économiques et technologiques : les établissements dans lesquels a été – ou est encore au moment de
      l’inscription – incubée / accélérée / hébergée l’entreprise le cas échéant ; les partenariats avec les laboratoires
      le cas échéant.
      <br /><br />
      En conséquence, lors de votre inscription, vous donnez votre consentement pour que les données citées ci-dessus
      soient visibles du grand public, sans que leur consultation soit soumise à une condition d’inscription sur la base de
      données Paris-Saclay Start-Up.
      <br /><br />
      Les informations visibles uniquement des utilisateurs inscrits :
      <br /><br />
      Les informations suivantes sont disponibles uniquement sur inscription préalable à la base de données
      Paris-Saclay Start-Up :<br />
      - Informations sur l’entreprise : Numéro de SIRET<br />
      - Informations personnelles concernant les créateurs de l’entreprise : Photo (éventuellement fournie),
      email personnel, numéro de téléphone<br />
      - Données économiques et technologiques : Chiffre d’affaires (si renseigné) ; Levées de fonds réalisées
      (si renseigné) ; Levée de fonds recherchée (si renseigné) ; Effectifs de l’entreprise ; Eventuelles récompenses et
      prix (si renseigné) ; Marchés sur lesquels se positionne l’entreprise (ses produits et / ou services) ;
      Domaines d’expertise de l’entreprise, Mots-clés choisis pour faciliter la recherche sur le portail (si renseigné) ;
      Brevets détenus par l’entreprise (si renseigné).
      <br /><br />
      En conséquence, lors de votre inscription, vous donnez votre consentement pour que les données listées
      ci-dessus (que vous aurez fournies volontairement en remplissant les champs obligatoires et éventuellement facultatifs)
      deviennent visibles pour les utilisateurs préalablement enregistrés, (créateurs de start-ups de l’écosystème
      Paris-Saclay et investisseurs).
    </Text>
    <Title>Consultation</Title>
    <Text>
      La consultation ou la réception de documents n’entraîne aucun transfert de droit de propriété intellectuelle
      en faveur de l’utilisateur.<br />
      Ce dernier s’engage à ne pas rediffuser ou à reproduire les données fournies.<br />
      Les données transmises sont traitées en conformité avec les usages en vigueur.
      (Voir page <Link href="%PUBLIC_URL%/../pdf/security-policy.pdf" target="_blank">« POLITIQUE DE SECURITE ET DE CONFIDENTIALITE »</Link>)<br />
      L’éditeur fournit l’information et les services associés en l’état et ne saurait accorder une
      garantie quelconque notamment pour la fiabilité, l’actualité, l’exhaustivité des données enregistrées.<br />
      L’utilisateur recherche, sélectionne et interprète les données sous sa propre responsabilité.<br />
      Toutes les informations sont données à titre indicatif.<br />
      Les éventuelles erreurs figurant dans les fichiers peuvent être signalées via la page « Contacts » du site.
    </Text>
    <Title>Confidentialité</Title>
    <Text>
      <Link href="https://www.epaps.fr/" target="_blank">l’Etablissement Public d’Aménagement Paris-Saclay</Link> et <Link href="https://lafrenchtech-paris-saclay.fr/" target="_blank">La French Tech Paris-Saclay</Link> se réservent le droit
      de contacter toute personne enregistrée sur le site.
    </Text>
    <Title>Utilisation de cookies</Title>
    <Text>
      Ce site Web nécessite l’utilisation de cookies.<br />
      Des informations non personnelles sont enregistrées par ce système de cookies
      (fichiers texte utilisés pour reconnaître un utilisateur et ainsi faciliter son utilisation du site).
    </Text>
    <Title>Conditions d’utilisation</Title>
    <Text>
      Les données contenues sur le site paris-saclay-startup.com sont protégées par la loi n° 98-536 du 1er juillet
      1998 concernant la protection juridique sur les bases de données.
      En accédant ou en utilisant le site, vous reconnaissez vous conformer aux dispositions de la loi, et
      notamment en vous interdisant l’extraction, le transfert, le stockage, la reproduction de tout ou partie
      qualitativement ou quantitativement substantielle du contenu des bases de données figurant sur le site.
      <br /><br />
      <strong>
        La reproduction, la rediffusion ou l’extraction automatique par tout moyen d’informations figurant sur
        paris-saclay-startup.com est interdite. L’emploi de robots, programmes permettant l’extraction directe de données
        est rigoureusement interdit.
      </strong>
      <br /><br />
      <Link href="https://www.epaps.fr/" target="_blank">l’Etablissement Public d’Aménagement Paris-Saclay</Link> et <Link href="https://lafrenchtech-paris-saclay.fr/" target="_blank">La French Tech Paris-Saclay</Link> se réservent le
      droit d’engager toute action de nature à faire cesser de tels agissements.
      <br /><br />
      Le site paris-saclay-startup.com a été déclaré à la CNIL en application de l’article 16 de la loi du
      6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés (Récépissé N°2106856V0).
      Les données recueillies auprès de vous font l’objet d’un traitement informatisé.
      <br /><br />
    </Text>
    <Title>Données personnelles</Title>
    <Text>
      L’EPA Paris Saclay en tant que responsable de traitement s’engage à ce que la collecte et le traitement de vos données soient conformes à la loi Informatique et Libertés du 6 janvier 1978 et au nouveau règlement général sur la protection des données dit RGPD (UE) 2016/679 du 27 avril 2016, en vigueur à compter du 25 mai 2018.
      <br />
      Les informations recueillies, notamment lors de votre inscription, font l’objet d’un traitement informatique.
      <br />
      Les données personnelles ainsi recueillies ne seront pas conservées au-delà de 3 ans.
      <br />
      Conformément à la réglementation, vous disposez d’un droit d’accès, de rectification et de suppression des informations qui vous concernent, vous pouvez accéder à ces informations en envoyant un message à notre DPO (Délégué à la protection des données personnelles) à l’adresse email suivante : dpo@oin-paris-saclay.fr
    </Text>
  </Container>
);

export default CguFr;
