import React from 'react';
import { ContainerQuery } from 'react-container-query';
import styled from 'styled-components';

import VideoScreen from 'components/video/VideoScreen';
import VideoText from 'components/video/VideoText';

const query = {
  small: {
    maxWidth: 600,
  },
  large: {
    minWidth: 856,
  },
};
const Container = styled.div`
    margin-top: ${({ large }) => (large ? '60px' : '30px')};
`;
const BlocVideo = styled.div`
    display: flex;
    align-items: center;
    flex-direction: ${({ large }) => (large ? 'row' : 'column')};
`;
const ColVideo = styled.div`
    ${({ large }) => (large ? 'width: 50%;' : null)};
    ${({ large }) => (large ? null : 'margin-bottom: 40px;')};
    ${({ large }) => (large ? 'padding-right: 60px' : null)};
    ${({ small }) => (small ? 'width: 100%;' : 'width: 60%;')};
    flex: 1;
`;
const ColText = styled.div`
    flex: 1;
`;
const Video = () => (
  <ContainerQuery query={query}>
    {(params) => {
      const { large, small } = params;
      return (
        <Container large={large}>
          <BlocVideo large={large}>
            <ColVideo
              small={small}
              large={large}
            >
              <VideoScreen />
            </ColVideo>
            <ColText>
              <VideoText />
            </ColText>
          </BlocVideo>
        </Container>
      );
    }}
  </ContainerQuery>
);

export default Video;
