import React from 'react';

import Navbar from 'components/navbar/Navbar';
import GlobalStyle from 'styles/Global';

const Header = () => (
  <>
    <Navbar />
    <GlobalStyle />
  </>
);

export default Header;
