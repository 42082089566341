import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
    flex-direction: column;
`;
const Title = styled.h2`
    font-size: 20px;
    color: #000000;
    margin-bottom: 20px;
`;
const BlocIntro = styled.p`
    color: #686868;
    letter-spacing: 0;
    line-height: 22px;
    margin-bottom: 10px;
`;
const BlocText = styled.p`
    color: #686868;
    letter-spacing: 0;
    line-height: 22px;
`;

const VideoText = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title>
        {t('homepage.video.title')}
      </Title>
      <BlocIntro>
      <strong>
          {t('homepage.video.blocIntroTextStrong')}
        </strong>
        {t('homepage.video.blocIntroText')}
      </BlocIntro>
      <BlocText>
        <strong>
          {t('homepage.video.blocIntroStrong')}
        </strong>
        {t('homepage.video.blocIntro')}
      </BlocText>
    </Container>
  );
};

export default VideoText;
