import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";
import { getImg } from "pages/utils";

export const Col = styled.div`
  flex: 0 0 calc(33.33% - 30px);
  margin-bottom: 10px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  margin: 0px 15px 30px 15px;
`;
const StyledLink = styled(Link)`
  position: relative;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  filter: ${({ oldevent }) =>
    oldevent ? "grayscale(100%);" : "grayscale(0%);"};

  &:hover {
    & img {
      transform: scale(1.2);
      transition: all 8s;
    }
    & h3 {
      color: ${({ oldevent }) => (oldevent ? "#000000;" : "#FF3330;")};
      transition-duration: 0.2s;
    }
  }
`;
export const BlocImg = styled.div`
  overflow: hidden;
  height: 120px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;
export const Img = styled.img`
  width: 100%;
  display: block;
  object-position: 50% 50%;
  object-fit: cover;
  height: 120px;
  transition: all 8s;
`;
export const BlocInfo = styled.div`
  padding: 30px;
  flex: 1 0 auto;
`;
export const Title = styled.h3`
  font-size: 16px;
  color: #000000;
  margin-bottom: 15px;
`;
const BlocDate = styled.div`
  font-size: 12px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 5px;
`;
const SpanArrow = styled.span`
  color: #ff3330;
`;
const Tag = styled.span`
  background: #1f7fee;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 110px;
  border-radius: 3px;
  padding: 5px 15px;
  color: #ffffff;
  font-size: 10px;
  color: #ffffff;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-weight: bold;
  z-index: 3;
  background: ${({ eventStatus, t }) =>
    eventStatus === t("events.pending") ? "#FF3330;" : "#1F7FEE;"};
`;
const Inno = styled.div`
  font-size: 12px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 15px;
`;
export const Hr = styled.hr`
  height: 1px;
  border-width: 0;
  background: #d2d2d2;
`;
export const Desc = styled.p`
  color: #686868;
  letter-spacing: 0;
  line-height: 22px;
  margin: 15px 0;
  flex: 1 0 auto;
`;
export const SpanButton = styled.a`
  display: block;
  text-align: center;
  background: #ff3330;
  border-radius: 5px;
  color: #ffffff;
  padding: 10px 10px;
  text-decoration: none;
  margin: 0px 30px 20px;
`;

const Event = (props) => {
  const {
    event: { img, title, desc, id, date, date_fin: dateFin, inno },
  } = props;

  const src = getImg(img);

  const { t } = useTranslation();
  let eventStatus = "";
  const today = moment();

  if (date < today) {
    if (dateFin < today) {
      eventStatus = t("events.finished");
    } else {
      eventStatus = t("events.pending");
    }
  } else {
    if (dateFin > today) {
      eventStatus = t("events.upcoming");
    } else {
      eventStatus = t("events.pending");
    }
  }

  let innoTitle = "";
  if (inno !== null && inno.length > 0) {
    innoTitle = inno[0].title;
  }

  let description = "";
  if (desc !== null && desc.length > 150) {
    description = `${desc.substring(0, 150)}...`;
  } else {
    description = "...";
  }

  return (
    <Col>
      <StyledLink
        to={{
          pathname: `/event/${id}`,
          isModal: true,
        }}
        oldevent={eventStatus === t("events.finished")}
      >
        <BlocImg>
          <Img src={src} alt={title} />
        </BlocImg>
        <Tag eventStatus={eventStatus} t={t}>
          {eventStatus}
        </Tag>
        <BlocInfo>
          <Title>{title}</Title>
          <BlocDate>
            {moment(date).format(t("events.dateFormat"))}{" "}
            <SpanArrow> &rarr; </SpanArrow>
            {moment(dateFin).format(t("events.dateFormat"))}
          </BlocDate>
          <Inno>{innoTitle}</Inno>
          <Hr />
          <Desc>{description}</Desc>
        </BlocInfo>
        <SpanButton>{t("events.modalLink")}</SpanButton>
      </StyledLink>
    </Col>
  );
};

Event.propTypes = {
  event: PropTypes.shape({
    date: PropTypes.instanceOf(moment),
    dateFin: PropTypes.instanceOf(moment),
    desc: PropTypes.string,
    id: PropTypes.number.isRequired,
    img: PropTypes.arrayOf(PropTypes.string.isRequired),
    inno: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
      })
    ).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default Event;
