import React, { useState, useEffect } from "react";
import { ContainerQuery } from "react-container-query";
import styled from "styled-components";
import _filter from "lodash/filter";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";
import DotLoader from "react-spinners/DotLoader";

import Event, { Col } from "components/events/Event";
import FilterDate from "components/events/FilterDate";
import FilterInno from "components/events/FilterInno";
import EventsHome from "./EventsHome";
import { eventsHomeInfos } from "./utils";

const query = {
  small: {
    maxWidth: 600,
  },
  medium: {
    minWidth: 601,
    maxWidth: 855,
  },
  large: {
    minWidth: 856,
  },
  xLarge: {
    maxWidth: 900,
  },
};
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin: 0 -15px;

  ${Col} {
    min-width: ${({ large }) => (large ? "calc(33.33% - 30px)" : null)};
    min-width: ${({ medium }) => (medium ? "calc(50% - 30px)" : null)};
    min-width: ${({ small }) => (small ? "calc(100% - 30px)" : null)};
  }
`;
const RowFilter = styled.div`
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 15px 25px 25px;
  margin-bottom: 30px;
  background: #ffffff;
`;
const ColTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
const Img = styled.img`
  width: 14px;
  height: 15px;
  margin-right: 10px;
`;
const Title = styled.div`
  font-weight: 600;
  font-size: 15px;
  color: #242424;
  letter-spacing: 0;
  line-height: 30px;
`;
const ColFilter = styled.div`
  display: flex;
  flex-direction: ${({ small }) => (!small ? "row" : "column")};
  justify-content: flex-start;
`;
const ColDate = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${({ small }) => (small ? 0 : "15px")};
  margin-bottom: ${({ small }) => (!small ? 0 : "15px")};
  flex: ${({ small }) => (small ? "0 0 auto" : "0 0 280px")};
`;
const SubtitleDate = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: #242424;
  margin-bottom: 5px;
`;
const InputDate = styled.div`
  display: flex;
  flex-direction: ${({ large }) => (!large ? "column" : "row")};
`;
const ColInno = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: ${({ small }) => (small ? "100%" : "calc(100% - 295px)")};
`;
const SubtitleInno = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: #242424;
  margin-bottom: 5px;
`;
const InputInno = styled.div`
  display: flex;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
const NoData = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  color: #b6b6b6;
  font-weight: bold;
  margin-top: 40px;
`;
const Events = (props) => {
  const { events, filter, loading } = props;

  const { t } = useTranslation();
  const [filteredEvents, setEvents] = useState([]);
  const [filteredEventsByDate, setEventsByDate] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(null);
  const [selectedLocations, setselectedLocations] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);

  const onChange = (value) => {
    if (value) {
      const { location, endDate, startDate } = value;
      const valuesKeys = Object.keys(value);
      if (["startDate", "endDate"].some((ai) => valuesKeys.includes(ai))) {
        setStartDate(startDate);
        setEndDate(endDate);
        setselectedLocations([]);
      }
      if (location) {
        setselectedLocations(location);
      }
    }
  };

  const filterByDate = () => {
    if (allEvents.length) {
      let filterArray = _filter(allEvents, (o) => {
        const { date } = o;
        return (
          moment(date) > moment(startDate) &&
          (!endDate || moment(date) < moment(endDate))
        );
      });

      if (!filterArray.length) {
        if (firstLoad) {
          filterArray = allEvents.slice(allEvents.length - 6, allEvents.length);
          setFirstLoad(false);
        } else if (!startDate && !endDate) {
          filterArray = allEvents;
        }
      }

      filterArray = sortBy(filterArray, "date");
      setEvents(filterArray);
      setEventsByDate(filterArray);
      filterByLocation(filterArray);
    }
  };

  const filterByLocation = (events) => {
    let filterArray = _filter(events, (o) => {
      const { inno } = o;
      const idEvent = map(inno, "key");
      const idInno = map(selectedLocations, "key");
      if (!idInno.length) {
        return true;
      }
      return idInno.indexOf(idEvent[0]) >= 0;
    });
    filterArray = sortBy(filterArray, "date");
    setEvents(filterArray);
  };

  useEffect(() => {
    filterByLocation(filteredEventsByDate);
  }, [selectedLocations, filteredEventsByDate]);

  useEffect(() => {
    filterByDate();
  }, [startDate, endDate, allEvents]);

  useEffect(() => {
    setAllEvents(events);
  }, [events]);

  const eventsView = (large) => {
    return loading ? (
      <LoadingContainer>
        <DotLoader />
      </LoadingContainer>
    ) : filteredEvents.length ? (
      Object.values(filteredEvents).map((item) => (
        <Event key={item.id} event={item} large={large} />
      ))
    ) : (
      <NoData>{t("events.noData")}</NoData>
    );
  };
  return (
    <ContainerQuery query={query}>
      {(params) => {
        const { large, medium, small, xLarge } = params;
        return (
          <>
            {filter ? (
              <>
                <RowFilter>
                  <ColTitle>
                    <Img
                      src={`${process.env.PUBLIC_URL}/img/picto-filter.svg`}
                    />
                    <Title>{t("eventspage.filter.title")}</Title>
                  </ColTitle>
                  <ColFilter small={small}>
                    <ColDate small={small}>
                      <SubtitleDate>
                        {t("eventspage.filter.dateTitle")}
                      </SubtitleDate>
                      <InputDate large={large}>
                        <FilterDate
                          large={large}
                          small={small}
                          onChange={onChange}
                        />
                      </InputDate>
                    </ColDate>
                    <ColInno small={small}>
                      <SubtitleInno>
                        {t("eventspage.filter.innoTitle")}
                      </SubtitleInno>
                      <InputInno>
                        <FilterInno
                          events={filteredEventsByDate}
                          onChange={onChange}
                          value={selectedLocations}
                        />
                      </InputInno>
                    </ColInno>
                  </ColFilter>
                </RowFilter>
                <Row large={large} medium={medium} small={small}>
                  {eventsView(large)}
                </Row>
              </>
            ) : (
              <Row large={large} medium={medium} small={small}>
                {eventsHomeInfos.map((infos) => {
                  const { id } = infos;
                  return <EventsHome key={id} {...infos} />;
                })}
              </Row>
            )}
          </>
        );
      }}
    </ContainerQuery>
  );
};

Events.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string,
      date: PropTypes.instanceOf(moment),
      date_fin: PropTypes.instanceOf(moment),
      desc: PropTypes.string,
      id: PropTypes.number,
      img: PropTypes.arrayOf(PropTypes.string),
      inno: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.number.isRequired,
          title: PropTypes.string.isRequired,
        })
      ),
      partners: PropTypes.string,
      price: PropTypes.string,
      speakers: PropTypes.string,
      title: PropTypes.string,
    }).isRequired
  ).isRequired,
  filter: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
};

Events.defaultProps = {
  filter: false,
};

export default Events;
