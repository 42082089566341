import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import WithLayout from 'layout/WithLayout';
import Intro from 'components/Intro';
import CguEn from 'components/cgu/CguEn';
import CguFr from 'components/cgu/CguFr';

const Container = styled.div`
    max-width: 1050px;
    margin: 0 auto;
    position: relative;
    margin-top: -50px;

    @media (max-width: 1100px) {
        padding-left:15px;
        padding-right: 15px;
    }
`;

const Cgu = () => {
  const { i18n } = useTranslation();
  const lang = i18n.languages[0];
  const  CguText = lang === 'en' ? CguEn : CguFr;
  return (
    <>
    <Intro
      isCgu
    />
    <Container>
      <CguText />
    </Container>
  </>
  )
};

export default WithLayout(withRouter(Cgu));
