import React from "react";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import WithLayout from "layout/WithLayout";
import Counters from "components/counter/Counters";
import Video from "components/video/Video";
import { fetcher, parseData } from "pages/utils";
import Events from "components/events/Events";
import News from "components/news/News";
import IntroHome from "components/IntroHome";
import BlocHome from "components/BlocHome";
import moment from "moment";
import { blocHomeInfos } from "../components/utils";

const Container = styled.div`
  max-width: 1050px;
  margin: 0 auto;
  margin-top: -50px;
  position: relative;

  @media (max-width: 1100px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0 40px;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 40px 0 20px;
  }
`;
const ColTitleEvents = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 700px) {
    margin-bottom: 30px;
  }
`;
const Img = styled.img`
  width: 18px;
  height: 19px;
  margin-right: 15px;
`;
const TitleEvents = styled.h2`
  font-size: 20px;
  color: #000000;
  letter-spacing: 0;
  line-height: 30px;
`;
const ColLink = styled.div`
  display: block;
  align-self: flex-end;
`;
const ColTitleNews = styled.div`
  display: flex;
  align-items: center;
  margin: 40px 0;

  @media (max-width: 700px) {
    margin: 40px 0 20px;
  }
`;
const StyledLink = styled.a`
  background: #ff3330;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  letter-spacing: 0;
  text-align: center;
  line-height: 33px;
  padding: 12px 23px;
  text-decoration: none;
  margin-bottom: 20px;
`;

const EventsBloc = styled(Events)`
  margin-bottom: 20px;
`;

const TitleNews = styled.h2`
  font-size: 20px;
  color: #000000;
  letter-spacing: 0;
  line-height: 30px;
`;

const Home = () => {
  const { t, i18n } = useTranslation();
  const { data, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/services/api.php?task=getConfig`,
    fetcher
  );
  const { data: dataCounters, errordataCounters } = useSWR(
    `${process.env.REACT_APP_API_URL}/services/api.php?task=getLocalData`,
    fetcher
  );
  const { counters: countersObject, maj } = dataCounters || {};
  const majDate = data ? moment().unix() : maj;

  const {
    counters = countersObject || [],
    events = [],
    news = [],
  } = parseData(data);

  if (error) return <div>failed to load</div>;
  const loading = !data;
  const loadingCounters = !countersObject;

  return (
    <>
      <IntroHome />
      <Container>
        <Counters counters={counters} loading={loading} maj={majDate} />
        <Video />
        <Row>
          <ColTitleEvents>
            <Img src={`${process.env.PUBLIC_URL}/img/picto-date.svg`} />
            <TitleEvents>{t("homepage.events.title")}</TitleEvents>
          </ColTitleEvents>
          <ColLink>
            <StyledLink
              href="https://lafrenchtech-paris-saclay.fr/event/ "
              target="_blank"
            >
              {t("homepage.events.eventsLink")}
            </StyledLink>
          </ColLink>
        </Row>
        <Events events={events} isHome loading={loading} />
        {blocHomeInfos.map((infos) => {
          return <BlocHome key={`${infos.id}-bloc`} {...infos} />;
        })}
        <ColTitleNews>
          <Img src={`${process.env.PUBLIC_URL}/img/picto-news.svg`} />
          <TitleNews>News Startups</TitleNews>
        </ColTitleNews>
        <News news={news} loading={loading} />
      </Container>
    </>
  );
};

export default withRouter(WithLayout(Home));
