import React from 'react';
import Select, { components } from 'react-select';
import styled from 'styled-components';
import map from 'lodash/map';
import flatten from 'lodash/flatten';
import keyBy from 'lodash/keyBy';
import PropTypes from 'prop-types';

const LengthSpan = styled.span`
  position: absolute;
  right: 0;
  top: 3px;
  color: #cccccc;
  font-size: 10px;
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    background: state.isFocused ? '#000000' : state.isSelected ? '#FF3330' : '#ffffff',
    color: state.isFocused || state.isSelected ? '#ffffff' : '#000000',
    cursor: 'pointer',
  }),
  control: (base) => ({
    ...base,
    border: '1px solid #E9E9E9',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #E9E9E9',
    },
    cursor: 'pointer',
  }),
  valueContainer: (provided) => ({
    ...provided,
    textOverflow: "ellipsis",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "initial",
    textAlign: 'left',
    cursor: 'pointer',
  }),
};

const multiValueContainer = ({ selectProps, data }) => {
  const { title, key } = data;
  const allSelected = selectProps.value;
  const index = allSelected.findIndex(selected => selected.key === key);
  const isLastSelected = index === allSelected.length - 1;
  const labelSuffix = isLastSelected ? '' : ", ";
  const val = `${title}${labelSuffix}`;
  return val;
};

const valueContainer = ({ children, getValue, ...props }) => {
  var valueLength = getValue().length;

  return (
    <components.ValueContainer {...props}>
      {children}
      {valueLength > 0 && <LengthSpan>[{valueLength}]</LengthSpan>}
    </components.ValueContainer>
  );
};

const SelectInput = styled.div`
    width: 100%;
    flex: 1;
    text-align: center;
    `;

const parseEventsLocation = (events) => {
  const eventsMap = map(events, 'inno');
  const flattenEvents = flatten(eventsMap);
  return Object.values(keyBy(flattenEvents, 'key'));
};

const FilterInno = (props) => {
  const { events, onChange, value } = props;
  const options = parseEventsLocation(events);

  return (
    <>
      <SelectInput>
        <Select
          styles={customStyles}
          defaultValue={value}
          value={value}
          isMulti
          name="colors"
          options={options}
          getOptionLabel={(option) => option.title}
          getOptionValue={(option) => option.key}
          onChange={(value) => onChange({ location: value })}
          placeholder="..."
          components={{
            MultiValueContainer: multiValueContainer,
            ValueContainer: valueContainer
            // Option: CustomOption,
          }}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isSearchable={false}
        />
      </SelectInput>
    </>
  );
};

FilterInno.propTypes = {
  onChange: PropTypes.func.isRequired,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string,
      date: PropTypes.instanceOf(Date),
      date_fin: PropTypes.instanceOf(Date),
      desc: PropTypes.string,
      id: PropTypes.number.isRequired,
      img: PropTypes.arrayOf(PropTypes.string),
      inno: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.number,
        title: PropTypes.number,
      })).isRequired,
      partners: PropTypes.string,
      price: PropTypes.string,
      speakers: PropTypes.string,
      title: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default FilterInno;
