import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import reduce from 'lodash/reduce';
import moment from 'moment';

export const fetcher = (url) => fetch(url).then((r) => r.json());

const getNews = (data) => get(data, 'news.data');

const parseEvents = (events) => {
  const eventId = events.id;
  const fieldsObject = keyBy(events.fields, 'key');
  const eventDate = moment(fieldsObject['event-date-debut'].value);
  const eventEndDate = moment(fieldsObject['event-date-fin'].value);
  const event = {
    date: eventDate,
    date_fin: eventEndDate,
    desc: fieldsObject['event-description'].value,
    title: fieldsObject['event-title'].value,
    id: eventId,
    img: fieldsObject['event-visuel-public'].value,
    inno: fieldsObject['event-innovation'].value,
    address: fieldsObject['event-adresse'].value,
    partners: fieldsObject['event-partenaires'].value,
    speakers: fieldsObject['event-intervenants'].value,
    price: fieldsObject['event-prix'].value,
    links: fieldsObject['event-links'].value,
  };

  return event;
};

const getEvents = ({ data, from, limit }) => {
  const dataEvents = get(data, 'evenement.data');
  const events = [];
  const lastEvents = reduce(Object.values(dataEvents), (acc, o) => {
    const event = parseEvents(o);
    const eventDate = event.date;

    if (eventDate > from) {
      acc.push(event);
    }

    events.push(event);
    return acc;
  }, []);

  if (!limit) {
    return sortBy(events, 'date');
  }

  const lastEventsLength = lastEvents.length;
  let nextEvents = [];

  if (lastEventsLength < limit) {
    nextEvents = sortBy(events, 'date').reverse().slice(0, limit || events.length);
    nextEvents = sortBy(nextEvents, 'date');
  } else {
    nextEvents = sortBy(lastEvents, 'date').slice(0, limit || lastEventsLength);
  }

  return nextEvents;
};

export const checkBrowser = () => {
  const { detect } = require('detect-browser');
  const browser = detect();
  const browserArray = [
    {
      name: 'chrome',
      version: 75,
    },
    {
      name: 'firefox',
      version: 68,
    },
    {
      name: 'edge',
      version: 17,
    },
    {
      name: 'edge-chromium',
      version: 83,
    },
    {
      name: 'safari',
      version: 12,
    },
    {
      name: 'internet-explorer',
      version: 11,
    },
  ];
  let supported = true;
  Object.values(browserArray).map((item) => {
    if (item.name === browser.name && item.version >= parseInt((browser.version), 10)) {
      supported = false;
    }
  });

  return supported;
};

export const parseData = (data) => {
  if (!data) return [];
  const dataArray = {};
  dataArray.events = getEvents({ data, from: moment(), limit: 3 });
  dataArray.news = getNews(data);
  return dataArray;
};

export const getAllEvents = (data) => {
  if (!data) return [];
  const dataArray = getEvents({ data, from: moment(), limit: null });
  return dataArray;
};

export const getImg = (image) => {
  const noPic = `${process.env.PUBLIC_URL}/img/no-pic.png`;
  if(!image) {
    return noPic;
  }
  const { path } = JSON.parse(image)[0];
  return path ? `${process.env.REACT_APP_PLATFORM_URL}${path}` : noPic;
}
